const newsSubpath = '/service-news'

export const servicesRoutes = [
  {
    path: `${newsSubpath}`,
    name: 'News Index',
    meta: { showFooter: true, showHeader: true },
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/news/Index.vue')
  },
  {
    path: `${newsSubpath}/:slug`,
    name: 'News',
    meta: { showFooter: true, showHeader: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: "news" */ '@/views/news/Show.vue')
  }
]
